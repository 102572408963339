<template>
  <div>
    <h1 style="text-align: center">404</h1>
    <h2 style="text-align: center">Page Not Found</h2>
    <br />
    <p style="text-align: center">申し訳ありません。</p>
    <p style="text-align: center">お探しのページが見つかりませんでした。</p>
    <br />
    <br />
    <div class="text-center">
      <v-btn outlined color="success" @click="backToDashboard">ダッシュボード</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    backToDashboard() {
      this.$router.push({ path: '/' });
    },
  },
};
</script>
<style scoped>
h1 {
  font-weight: bold;
  font-size: 128px;
}
</style>
